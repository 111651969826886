import type { HeroFieldsFragment, MediaSliderFieldsFragment, UpsellFieldsFragment } from '@news/gql';

import { setURLParameters } from './utils';

const UTM_SOURCE = 'tv4.se';
const UTM_CAMPAIGN_PLAY = 'upsell';

type UTMMedium =
  | 'Link'
  | 'RedBanner'
  | UpsellFieldsFragment['__typename']
  | HeroFieldsFragment['__typename']
  | MediaSliderFieldsFragment['__typename']
  | 'EditorialBanner'
  | 'MediaSliderItem';

const partners = ['tv4play.se', 'fotbollskanalen.se', 'koket.se'];

export const isPartner = (href: string) => {
  return partners.some((partner) => href.includes(partner));
};

export const appendUtmTags = (href: string, medium: UTMMedium, upsell = true) => {
  // Only apply utm tags for tv4.se partners.
  if (!isPartner(href)) {
    return href;
  }

  const parametersToAdd: Record<string, string> = {
    utm_source: UTM_SOURCE,
    utm_medium: medium,
  };

  if (href.includes('tv4play.se') && upsell) {
    parametersToAdd['utm_campaign'] = UTM_CAMPAIGN_PLAY;
  }

  return setURLParameters(href, parametersToAdd);
};
