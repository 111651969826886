import type { NewsFeedItem } from '@news/lib';

import type { FlattenedFeed } from 'shared/types';

import { mapNoEmpty } from './mapNoEmpty';

export const flattenFeed = (feed: NewsFeedItem[]): FlattenedFeed[] => {
  return feed.reduce((arr: FlattenedFeed[], item) => {
    if (item.__typename === 'Article' && item.related) {
      const related = mapNoEmpty(
        // TODO: Remove VideoAsset type check when it is removed from the GraphQL schema
        (row) => (row && row.__typename !== 'VideoAsset' && row.id ? row : null),
        item.related
      ).map((row) => {
        return { ...row, isRelated: true };
      });
      return [...arr, item, ...related];
    } else if (item.__typename === 'VideoAsset') {
      return [...arr, item];
    } else {
      return arr;
    }
  }, []);
};
