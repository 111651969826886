import type {
  BreakingNewsFieldsFragment,
  ExternalLinkFieldsFragment,
  FeedLinkFieldsFragment,
  InternalLinkFieldsFragment,
  LinkFieldsFragment,
  MediaPanelItemFieldsFragment,
  RelatedArticleFieldsFragment,
  VideoPlayerDataFieldsFragment,
} from '@news/gql';
import type { ArticleBody, ArticleMeta, NewsEntry, NewsFeedItem } from '@news/lib';
import { getItemUrlForFeedItem } from '@news/lib';

import type { ItemData, ItemGroupData } from '../item';
import { feedOriginToTags, getTagsForItem } from './getTagsForItem';

export type TrackedEditorialBannerData = ArticleBody<'EditorialBannerData'> & {
  articleMeta?: ArticleMeta;
};

export type TrackedItem =
  | Omit<ItemData, keyof ItemGroupData>
  | NewsEntry
  | Exclude<NewsFeedItem, { __typename: 'VideoPlayerData' }> //The exclusion of VideoPlayerData is to be removed once we update the clients to fetch VideoPlayerData as feed items
  | RelatedArticleFieldsFragment
  | BreakingNewsFieldsFragment
  | FeedLinkFieldsFragment
  | ExternalLinkFieldsFragment
  | InternalLinkFieldsFragment
  | LinkFieldsFragment
  | TrackedEditorialBannerData
  | MediaPanelItemFieldsFragment
  | VideoPlayerDataFieldsFragment;

export const getItemData = (item: TrackedItem): Omit<ItemData, keyof ItemGroupData> => {
  if (!('__typename' in item)) {
    return {
      ...item,
    };
  }

  switch (item.__typename) {
    case 'Article':
    case 'RelatedArticle':
    case 'VideoAsset':
    case 'BreakingNews':
      return {
        item_id: item.id,
        item_title: item.title,
        item_type: item.__typename,
        item_target_url: getItemUrlForFeedItem(item),
        item_tags: getTagsForItem(item),
      };
    case 'VideoPlayerData':
      return {
        item_id: item.id,
        item_title: item.caption ?? item.asset?.title ?? null,
        item_type: item.__typename,
        item_target_url: null,
        item_tags: null,
      };
    case 'MediaSlider':
      return {
        item_id: item.id,
        item_title: item.title,
        item_type: item.__typename,
        item_target_url: null,
        item_tags: null,
      };
    case 'MediaPanelItem':
      return {
        item_id: item.id,
        item_title: item.title,
        item_type: 'MediaSliderItem',
        item_target_url: item.link,
        item_tags: null,
      };
    case 'EditorialBannerData':
      return {
        /** TODO: Should be id when available */
        item_id: item.bannerTitle,
        item_title: item.bannerTitle,
        item_target_url: item.buttonLink,
        item_type: 'EditorialBanner',
        item_tags: item.articleMeta ? feedOriginToTags(item.articleMeta.feedOrigins) : null,
      };
    case 'BreakingNewsModule': {
      return {
        item_id: item.id,
        item_type: item.__typename,
        item_title: null,
        item_target_url: null,
        item_tags: null,
      };
    }
    case 'LiveReport': {
      return {
        item_id: item.id,
        item_title: item.title,
        item_type: item.__typename,
        item_target_url: null,
        item_tags: null,
      };
    }
    case 'Upsell':
      return {
        item_id: item.id,
        item_title: item.title,
        item_type: item.__typename,
        item_target_url: item.linkUrl,
        item_tags: null,
      };
    case 'Link':
      return {
        item_id: item.id,
        item_title: item.title,
        item_type: item.targetType ?? '',
        item_target_url: item.href,
        item_tags: null,
      };
    case 'FeedLink':
      return {
        item_id: item.id,
        item_target_url: item.slug,
        item_title: item.title,
        item_type: item.__typename,
        item_tags: null,
      };
    case 'InternalLink':
    case 'ExternalLink': {
      return {
        item_id: item.id,
        item_title: item.title,
        item_target_url: item.url,
        item_type: item.__typename,
        item_tags: null,
      };
    }
  }
};
