import type { DeprecatedNewsFeedItem, GeneratedNewsFeedItem, GeneratedNewsFeedItemForPlugin } from '../types';

export const isNotDeprecatedNewsFeedItem = <T extends Pick<GeneratedNewsFeedItem, '__typename'>>(
  input: T
): input is Exclude<T, DeprecatedNewsFeedItem> =>
  input.__typename !== 'FrequentlyAskedQuestions' && input.__typename !== 'MostViewedVideos';

export const isNotDeprecatedNewsFeedItemForPlugin = <T extends Pick<GeneratedNewsFeedItemForPlugin, '__typename'>>(
  input: T
): input is Exclude<T, DeprecatedNewsFeedItem> =>
  input.__typename !== 'FrequentlyAskedQuestions' && input.__typename !== 'MostViewedVideos';
